.pagination-wrap,
.result-wrap {
	margin-top: $margin-md;

	&__snug {
		margin-top: 0;
	}
}

.result-wrap,
.content-result-wrap {
	
	.result-grid {
		margin-left: -#{$result-grid-padding};
		margin-right: -#{$result-grid-padding};
	}

	.result-item {
		border-left: $result-grid-padding solid $white;
		border-right: $result-grid-padding solid $white;
	}
}

.filter-form {
	.archive-loader {
		opacity: 0;
		@extend .transition-fast;

		img {
			width: 100px;
		}
	}

	&.infinite-loading {
		.archive-loader {
			opacity: 1;
		}
	}
}

.search-results {
	&__search-keyword {
		margin-top: $space;
	}
}

.archive-term-description {
	padding-top: $margin-md;
}