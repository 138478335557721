input, .form-control {
	height: $input-height-sm;
	width: 100%;
	font-family: $font-family-heading;
	font-size: $font-size-lg;

	@include media-site-breakpoint() {
		font-size: $font-size-base;
	}
}

select {
	&.b-select {
		border: none;
	}
}

.form-control-dark {
	background-color: $black;
	color: $white;
  	border-radius: $border-radius;
  	border-color: $black;
	border-width: 2px;

  	&:focus {
  		border-color: $white;
  	}
}

.form-control-clear {
	color: $black;
	border: none;
	border-radius: $border-radius;
}

.input-lg {
	height: $input-height-lg;
	padding-left: $margin;
	padding-right: $margin-lg;

	@include media-breakheight-md() {
		height: $input-height-md;
	}
}

.input-md {
	height: $input-height-sm;
}

.input-btn {
	position: absolute;
	right: 0;
	top: 0;
	width: $margin-lg;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	&-md {
		width: $margin;
	}
}

// SEARCH FORM
.search-form {
  // @extend .form-inline;
}
.search-form,
.page-search {
	position: relative;
	width: 100%;

	label {
	font-weight: normal;
	display: flex;
  	// @extend .form-group;
	} 
}
.search-form,
.page-search {
	
	.search-field {
		width: 100%;
		
		&__clear {
			@extend .form-control-clear;
		}

		&__dark {
			@extend .form-control-dark;
		}

		&__break {
			@include media-site-breakpoint() {
				background-color: transparent;
				padding: 0;
				padding-right: $space-md;
				border: 0;
				border-bottom: 1px solid $white;
				height: $input-height-sm;

				& + .input-btn {
					width: auto;
				}
			}
		}
	}

}

.page-search {
	&-title,
	.search-field {
		font-size: $font-size-sm;

		@include media-site-breakpoint() {
			font-size: $font-size-base;
		}
	}
	
	&-title {
		@extend .reset-margin;
	}

	.search-field {
		padding: $space-xs 0;

		&::placeholder {
			color: $black;

			@include media-site-breakpoint() {
				font-size: $font-size-sm;
			}
		}
	}

	&-label {
		position: relative;
		border-bottom: $border-std;
		border-color: $black;
		
	}

	&__full {
		margin-bottom: $space-lg;

		@include media-site-breakpoint() {
			margin-bottom: $margin;
		}
		
		.page-search {
			&-label {
				border-bottom-color: $black;

				.search-field {
					padding: $space-sm 0;
					padding-right: $margin-md;
					@extend .type-alt-large;
					height: $margin-lg;

					@include media-site-breakpoint() {
						font-size: $font-size-base;

						&::placeholder {
							top: -$space;
							position: relative;
							width: 70%;
							white-space: pre-line;
							wrap: normal;
							font-size: $font-size-sm;
						}
					}
				}
			}

		}
		

	}
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.main-menu__search {
	.input-btn {
		.search-icon {
			#search-icon-g {
				fill: $gray-light;
			}
		}
	}
}

//FORMS

form {
	&.wpcf7-form {
		br {
			display: none;
		}

		[type="submit"] {
			margin-top: $space-lg;
		}

		.wpcf7-response-output {
			@extend .type-tiny;
			border: none;
			margin: 0;
			text-align: center;

			&.wpcf7-validation-errors {
				color: $primary;
			}
		}
	}
	
	.wpcf7-not-valid-tip {
		display: none;
	}

	.field-error {
		.text-wrap {
			color: $primary;
		}
	}

	.wpcf7-not-valid {
		border-color: $primary;
	}

	.captcha-row {
		margin-top: $margin;
		margin-bottom: $space-md;
		min-height: 80px;
	}
}

// CUSTOM RADIO SELECT
input {
	&[type="radio"],
	&[type="checkbox"] {
		
		&.input-filter {
			@extend .sr-only;
			
			& ~ .text-wrap {
				@extend .transition-fast;

			}
			
			&:checked {
				& ~ .text-wrap {
					color: $black;
				}
			}

			&.inactive {
				& ~ .text-wrap {
					color: $gray-light;
				}
			}

			&.term-disabled {
				& ~ .text-wrap {
					color: $gray-light;
					cursor: default;
				}

				&:hover {
					& ~ .text-wrap {
						color: $gray-light;
					}					
				}
			}

			&.default {

				& ~ .text-wrap {
					color: $primary;
				}

				&.inactive {
					& ~ .text-wrap {
						color: $primary-light;
					}
				}

				&:checked {
					& ~ .text-wrap {
						color: $primary;
					}
				}
			}

			&:hover,
			&.default:hover {
				& ~ .text-wrap {
					color: $primary;
				}
			}
		}
	}
}

// Custom Select 

.b-select {
	&.filters-select {
		height: auto;

		.dropdown-toggle {
			font-size: $font-size-sm;
			display: block;
			background-color: transparent;
			border: none;
			padding-left: 0;
			padding-right: 0;

			&:focus,
			&:hover,
			&:active {
				box-shadow: none !important;
				outline: none !important;
				border: none !important;
			}

			// &.bs-placeholder {
			// 	color: $black;
			// }

			&:hover {
				color: $primary;
			}

			&:after {
				content: '';
				background-image: url(../icons/ui_icon_dropdown_arrow.svg);
				background-size: contain;
				background-repeat: no-repeat;
				border: none;
				height: 13px;
				width: 13px;
				vertical-align: middle;
			}
		}

		.dropdown-menu {
			top: $margin-sm !important;
			font-size: $font-size-sm;

			.dropdown-item {
				&:hover,
				&.active {
					color: $primary;
					background-color: transparent;
				}
			}

		}
	}
}