.fc {
	&__post-content {
		&.post-content {
			margin-bottom: 0;
		}
	}

	&__image-mod {
		img {
			border: $border-std;
			width: 100%;
		}

		&-caption {
			@extend .text-gray;
			@extend .padding-sm;
			border-bottom: $border-std;
		}

		&-link {
			@extend .h4;
			padding-top: $margin;
			
			+ img {
				margin-top: $space-md;
			}

			a {
				color: $black;

				&:hover {
					color: $primary;
				}
			}
		}
	}

	&__quote {
		@extend .quote-box;
		// position: relative;
		// border-left: 4px solid $primary;
		// padding-left: $margin-lg;

		&-content {
			@extend .quote-box-content;
			// font-size: $font-size-h3;
			// font-family: $font-family-heading;
			// line-height: 37px;
			// @extend .text-gray;
			margin-bottom: $space-md;
		}

		&-credit {
			@extend .h5;
		}
	}

	&__video {
		&.internal {
			.media-item {
				padding-bottom: $height-widescreen;
			}
		}
	}


	&__downloads {

		&-title {
			@extend .h6;
			@extend .uc;
			margin-bottom: $margin;
		}
		
		&-list {
			@extend .list-unstyled;
		}

		&-list-item {
			display: flex;
			align-items: center;
			margin-bottom: $margin;

			.icon-placeholder {
				width: 28px;
				display: inline-block;
			}

			.icon-file-type,
			.icon-placeholder {
				margin-right: $margin;
			}

		}

		&-list-link {
			color: $black;

			&:hover {
				color: $primary;
			}

			~ svg {
				margin-left: $margin;	
			}
		}
		
	}

	&__collapse {
		&-title {
			margin-bottom: $space-lg;
		}
	}

	&__form {
		&-title {
			margin-bottom: $margin-lg;
		}

		&-col {
			&:nth-child(odd) {
				.fc__form-col-inner {
					padding-right: $space-lg;
				}	
			}

			&:nth-child(even) {
				.fc__form-col-inner {
					padding-left: $space-lg;
				}	
			}

		}

	}
}