.main-menu {
	&__container {
		height: 100%;
		// background-size: cover;
		// background-repeat: no-repeat;
		// background-position: top center;
		@extend .box-padding;
		
		@include media-breakheight-md() {
			padding-top: $space-md;
		}

		@include media-site-breakpoint() {
			padding-left: $margin;
			padding-right: $margin;
		}

		.site-container-default & {
			@include media-site-breakpoint() {
				// background-attachment: fixed;
			}
			
		}

		&:after {
			content: '';
			position: absolute;
			background-image: url(../images/map_bg.png);
			background-size: cover;
			height: 100vh;
			top: 0;
			left: 0;
			width: 100%;
			
			@include media-breakpoint-up(sm) {
				background-position-y: bottom;
			}

			@include media-breakheight-sm() {
				height: 100%;
			}
		}
	}

	&__brand,
	&__search,
	&__nav {
		position: relative;
		width: 100%;
		margin-bottom: $margin-25;
		color: $white;
		z-index: 1;

		@include media-site-breakpoint-up() {
			margin-bottom: $margin-md;
		}
	}

	&__brand {
		display: flex;
		@include media-site-breakpoint-up() {
			padding-top: $margin-md;
		}
		
		@include media-breakheight-md() {
			padding-top: $space-md;
		}

		.brand {
			&-logo {
				height: auto;
				z-index: 100;
				

				&-sm {
					@include media-site-breakpoint() {
						margin-top: -5px;
					}
				}

				@include media-site-breakpoint-up() {
					position: fixed;
					top: $margin-lg;
					left: $margin-lg;

					&-lg {
						width: 130px;
					}
				}

				@include media-breakheight-md() {
					top: $space-md;
				}

				@include media-breakheight-sm() {
					position: absolute;
					left: 0;
					top: 0;
				}
				
				.closed & {
					@include media-site-breakpoint-up() {
						width: 85px;
						top: $space-md;
						left: 6px;	
					}

					@include media-breakpoint-up(xl) {
						width: 90px;
						top: $space-md;
						left: 16px;
					}

					@include media-breakheight-sm() {
						position: fixed;
					}
				}
			}

			&-tagline {
				margin-left: 58%;
				text-align: right;
				
				@include media-breakheight-md() {
					margin-left: 40%;
					font-size: $font-size-base;
				}
			}
		}
	}
	
	&__search {

		@include media-site-breakpoint() {
			.site-container-default & {
				margin-bottom: $margin-md;
			}
		}

		.brand {
			&-tagline {
				display: none;
				
				@include media-site-breakpoint() {
					.home & {
						display: block;
						font-size: $font-size-sm;
					}
				}
			}
		}
	}

	&.static {
		.toggle-menu-btn {
			display: none;
		}
	}

	.toggle-menu-btn {
		@extend .h4;
		@extend .uc;

		@include media-site-breakpoint() {
			text-align: left;
			margin-bottom: 0;
			width: 45px;
			.toggle-text {
				display: block;
				font-size: $font-size-sm;
			}
		}

		@include media-site-breakpoint-up() {
			text-align: center;
		}
	}
	
	.toggle-open {
		position: fixed;
		top: 125px;
		left: 0;
		
		svg {
			display: none;
		}
		
		@include media-breakpoint-down(lg) {
			left: $margin-25;
		}

		@include media-site-breakpoint() {
			top: $margin;
			left: auto;
			right: $margin-25;

			svg {
				display: inline-block;
			}
		}

		.closed & {
			visibility: hidden;
		}


		@include media-breakpoint-up(xl) {
			width: $menu-closed-width-xl;
		}		
	}

	.toggle-close {
		position: absolute;
		top: 0;
		right: 0;

		.open & {
			visibility: hidden;
		}

		@include media-site-breakpoint() {
			position: fixed;
			top: $margin;
			right: $margin-25;

			svg {
				width: $margin;
				height: auto;
			}
		}
	}

	&__footer {
		position: absolute;
		bottom: $margin;
		color: $gray-light;
		z-index: 1;
		
		a {
			color: $gray-light;

			&:hover {
				color: $white;
				text-decoration: none;
			}
		}

		@include media-site-breakpoint() {
			position: relative;
			bottom: auto;
		}
	}
}