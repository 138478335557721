.slider {
	&__full-height {
		height: 100%;
		
		.owl {
			&-stage-outer,
			&-stage,
			&-item {
				height: 100%;
			}
		}
		.slide-item {
			height: 100%;
		}

	}
}
.post-slider {
	&-container {
		background-color: $white;
		display: flex;
		flex-direction: column;
		height: 100%;
		position: relative;
	}

	&-container__title {
		@extend .uc;
		margin: $margin 0 $space-md;
		padding: 0 $margin-md;

		@include media-site-breakpoint() {
			padding: 0 $margin-25;
		}

		@include media-breakheight-md() {
			margin-bottom: $margin;
		}
	}

	&-position {
		// left: 0;
		padding: 0 $margin-md;
		flex: 0 1 100%;

		@include media-site-breakpoint() {
			padding: 0 $margin-25;
		}
	}
	
	&-dots,
	&-nav {
		position: absolute;
	}

	&-dots {
	    width: 50%;
	    right: $margin;
	    bottom: $margin;
	    text-align: center;
	    z-index: 1;

		@include media-site-breakpoint() {
			text-align: right;
			bottom: auto;
			top: $space-lg;
			right: $margin-25;
		}
	}

	&-nav {
		top: 50%;
		left: 0;
		width: 100%;

	}

	.slide-item {
		a {
			color: $black;

			&:hover {
				text-decoration: none;
			}
		}

		&__title {
			@include media-breakpoint-up(xxxl) {
				font-size: $font-size-h2;
			}

			@include media-breakpoint-down(lg) {
				line-height: 30px;
			}

			@include media-breakheight-md() {
				font-size: $font-size-nav;
				line-height: 27px;
			}
		}
		
		&__excerpt {
			@include media-site-breakpoint() {
				display: none;
			}
		}

		&__thumb-wrap {
			margin-bottom: $margin;

			@include media-site-breakpoint-up() {
				padding-right: $space;
				margin-bottom: 0;
			}
		}

		&__content-wrap {
			
			@include media-site-breakpoint-up() {
				padding-left: $space;
			}
		}
		
		&__thumb {
			background-size: cover;
			background-repeat: no-repeat;
			height: 100%;
			display: block;

			@include media-site-breakpoint() {
				height: 230px;
			}
		}

		p {
			font-size: $font-size-sm;
		}

		.article-meta {
			margin-bottom: $margin;

			@include media-breakheight-md() {
				margin-bottom: $margin-sm;
			}
		}

	}
}