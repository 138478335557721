// Grid settings

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1900px
);

$site-grid-breakpoint: 992px;

$site-height-breakpoint-md: 980px;
$site-height-breakpoint-sm: 780px;

$site-grid-breakpoint-xs: 769px;

$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$body-min-height-sm: 800px;

$tablet: 768px;

// Custom Media breakpoint when site changes to responsive view
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-site-breakpoint() {
  $max: ($site-grid-breakpoint - .02px);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-site-breakpoint-up() {
  $min: ($site-grid-breakpoint);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-site-breakpoint-xs() {
  $max: ($site-grid-breakpoint-xs - .02px);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakheight-md() {
	$max: ($site-height-breakpoint-md);
	$width: ($site-grid-breakpoint);

	@if $max {
	  @media (max-height: $max) and (min-width: $width) {
	    @content;
	  }
	} @else {
	  @content;
	}
}

@mixin media-breakheight-sm() {
	$max: ($site-height-breakpoint-sm);
	$width: ($site-grid-breakpoint);

	@if $max {
	  @media (max-height: $max) and (min-width: $width) {
	    @content;
	  }
	} @else {
	  @content;
	}
}

@mixin media-breakpoint-tablet() {
	$max: $tablet;
	@if $max {
	  @media (max-width: $max) {
	    @content;
	  }
	} @else {
	  @content;
	}
}

@mixin media-breakpoint-tablet-plus() {
	$min: ($tablet + .02px);
	@if $min {
	  @media (min-width: $min) {
	    @content;
	  }
	} @else {
	  @content;
	}
}
// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// stylelint-disable
$grid-gutter-width: 40px;

$space: 15px;
$space-xxs: $space / 5;
$space-xs: $space / 3;
$space-sm: $space / 2; 
$space-md: $space * 2;
$space-lg: $space * 3;

$margin: 20px;
$margin-xs: $margin / 3;
$margin-sm: $margin / 2;
$margin-md: $margin * 2;
$margin-lg: $margin * 3;
$margin-xl: $margin * 4;

$margin-25: $margin + 5;

$spacer: 1rem;
$spacers: ();
$spacers: map-merge((
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
), $spacers);


$padding-sm: $margin;
$padding-md: $space-md;
$padding-std: $margin-lg;

$header-height-sm: 80px;

// Colors
//
$primary: #E4002B;
$primary-light: rgba(228,0,43,.15);
$gray-dark: #9A9A9A;
$gray-light: #E0E0E0;
$black: #000000;
$white: #FFFFFF;

$bg-hover: rgba(0, 0, 0, .5);
$bg-hover-gray: rgba(154,154,154,0.85);
$bg-hover-white: rgba(255, 255, 255, .9);

$white-clear: rgba(255, 255, 255, .7);
// $secondary:     $gray-600 !default;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-800 !default;



// Fonts, Font-sizes
//
$font-family-heading-bold: texgyreadventor-bold-webfont, Arial, sans-serif;
$font-family-heading: texgyreadventor-regular-webfont, Arial, sans-serif;

$font-family-base: Roboto-Regular, Arial, sans-serif;

$font-size-nav: 1.25rem; // 20px
$font-size-index: 4.75rem; // 76px
$font-size-main-nav: 4.875rem; // 78px
$font-size-lg: 1.125rem; // 18px
$font-size-sm: 0.875rem; // 14px
$font-size-fine: 0.8125rem; // 13px
$font-size-xs: 0.6875rem; // 11px

$font-size-h1: 2.875rem; // 46px
$font-size-h2: 2.25rem; // 36px
$font-size-h3: 1.5rem; // 24px
$font-size-h4: $font-size-lg;
$font-size-h5: $font-size-sm;
$font-size-h6: $font-size-xs;

// Responsive Font-sizes
$font-size-main-nav-sm: 2.5rem; // 40px
$font-size-nav-sm: $font-size-sm; // 14px
// $font-size-index-sm: 2.5rem; // 40px
$font-size-h1-sm: 2.25rem; // 36px
$font-size-h2-sm: 1.75rem; // 28px
$font-size-h3-sm: $font-size-lg; // 18px
$font-size-h4-sm: 1rem; // 16px;


$border-std: 1px solid $gray-light;
$border-plus: 2px solid $gray-light;
$border-radius:               0;
$border-radius-lg:            0;
$border-radius-sm:            0;


$input-height-lg: 70px;
$input-height-md: 60px;
$input-height-sm: 50px;

$btn-height-md: 55px;

$input-box-shadow: none;
$input-focus-box-shadow: $input-box-shadow;
$input-focus-border-color: $black;
$input-placeholder-color: $gray-light;

$input-border-color : $gray-light;
$input-focus-border-color : $black;
$input-box-shadow : none;
$input-padding-y: $space;
$input-padding-x: $space;

$ani-fast: .3s;
$ani-reg: 1s;
$ani-slow: 2s;


$menu-open-width-xl: 850px;
$menu-open-width-md: 768px;
$menu-open-width-sm: 100%;

$menu-closed-width-xl: 120px;
$menu-closed-width-sm: 100px;

$menu-closed-left-position-xl: ($menu-open-width-xl - $menu-closed-width-xl);
$menu-closed-left-position-md: ($menu-open-width-md - $menu-closed-width-sm);

$result-grid-padding : 1px;

// Card overwrites
//
$card-border-width:     0;
// $card-border-radius:    $border-radius !default;
$card-border-color:     $white;
$card-cap-bg:           $white;
$card-bg: 				$white;
// $card-spacer-y: 		0; 
// $card-spacer-x: 		0;

$height-widescreen : 56.25%;


// You can customize Simplelightbox with the following variables:

$sl-font-family: Arial, Baskerville, monospace !default;
$sl-overlay-background: $bg-hover-gray !default;
$sl-navigation-color: $primary !default;
$sl-caption-color: #fff !default;
$sl-caption-background: rgba(0, 0, 0, 0.8) !default;

$sl-counter-fontsize: 1rem !default;
$sl-close-fontsize: 3rem !default;

$sl-breakpoint-medium: 35.5em !default; // 568px, when 1em == 16px
$sl-breakpoint-large:	50em !default;	 // 800px, when 1em == 16px

$sl-arrow-fontsize-small:	2rem !default;
$sl-arrow-fontsize-medium: 3rem !default;
$sl-arrow-fontsize-large:	3rem !default;
$sl-img-border-small:	0 none !default;
$sl-img-border-medium: 0 none !default;
$sl-img-border-large:	0 none !default;
$sl-iframe-border-small:	0 none !default;
$sl-iframe-border-medium: 0 none !default;
$sl-iframe-border-large:	0 none !default;


$hero-section-height-lg: 80vh;
$hero-section-height-sm: 45vh;


// Bootstrap-select variables:

$color-red-error: rgb(185, 74, 72) !default;
$color-green-success: #28a745;
$color-grey-arrow: rgba(204, 204, 204, 0.2) !default;

$width-default: 220px !default; // 3 960px-grid columns

$zindex-select-dropdown: 1060 !default; // must be higher than a modal background (1050)

//** Placeholder text color
$input-color-placeholder: $black;
$input-alt-color-placeholder: rgba(255, 255, 255, 0.5) !default;

$input-padding-y-sm: .25rem !default;
$input-padding-x-sm: .5rem !default;

$input-padding-y-lg: 0.5rem !default;
$input-padding-x-lg: 1rem !default;