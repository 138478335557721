.pagination {
	justify-content: space-between;
	padding-top: $margin-sm;
	border-top: $border-std;

	.page-num,
	.page-dots,
	.link {
		@extend .l2;
		color: $gray-dark;
		
		&.active {
			color: $black;
		}

		&:hover {
			color: $primary;
		}

		&.disabled,
		&.page-dots {
			color: $gray-light;
			cursor: auto;
		}
	}
	
	.pg-direction-navs,
	.pg-item-navs {
		display: flex;
	}

	.pg-item-navs {
		justify-content: space-evenly;
		flex: 0 1 70%;
		.page-num {
		}
	}

	.pg-direction-navs {
		justify-content: space-between;

		flex: 0 1 15%;

		.pg-item-first {
			// margin-right: $margin-lg;
		}

		.pg-item-last {
			// margin-left: $margin-lg;
		}
	}


	// .pg-item-page {
	// 	flex-grow: 1;
	// 	text-align: center;
	// }
}