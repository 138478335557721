.cta {
	&-banner-wrap {
		display: flex;
		padding: $margin-lg 0;
		flex-direction: column;
		
		@include media-site-breakpoint-up() {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}

		.cta-title {
			@extend .type-quote;
			@extend .reset-margin;

			@include media-site-breakpoint() {
				margin-bottom: $margin;
				font-size: $font-size-lg;
				line-height: 24px;
			}
		}

		.cta-link {
			@extend .reset-margin;
		}
	}
}