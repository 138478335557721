// Grid system
.main {
  @include make-col-ready();
  padding: 0;
  
  @include media-breakpoint-up(sm) {
    @include make-col($main-sm-columns);
    .sidebar-primary & {
      @include make-col($main-sm-columns - $sidebar-sm-columns);
    }
  }
}
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($sidebar-sm-columns);
  }
}

.col-xs-full {
  @include media-site-breakpoint() {
    padding-left: 0;
    padding-right: 0;
  }
}

.stacked-grid {
  display: flex;
  flex-direction: column;

    @include media-breakheight-sm() {
      .site-container-fixed & {
        height: $body-min-height-sm;
      }
    }

  .grid-row__inner {
    height: 100%;
  }
}

.split-grid {
  display: flex;
  height: 100%;
  
  .grid-col-50 {
    flex: 0 1 50%;
  }

  .grid-col-40 {
    flex: 0 1 40%;

    @include media-breakheight-md() {
      flex-basis: 25%;
    }
  }

  .grid-col-60 {
    flex: 0 1 60%;

    @include media-breakheight-md() {
      flex-basis: 75%;
    }
  }

  .grid-col-xs-100 {
    @include media-site-breakpoint() {
      flex: 0 1 100%;
    }
  }

  &.split-xs-col {
    @include media-site-breakpoint() {
      flex-direction: column;
    }
  }
}

.module-grid {
  display: flex;
  
  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }

  .mod-col-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .mod-col-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .mod-col-md-6 {
    @include media-breakpoint-down(xl) {
      flex-basis: 50%;
      max-width: 50%;
    }
  }
  
  .mod-col-sm-12 {
    @include media-breakpoint-down(lg) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  .mod-col-12 {
    @include media-site-breakpoint() {
      flex: 0 1 100%;
      max-width: 100%;
    }
  }
}

.m-grid-container {
  position: relative;
  width: 100%;

  .m-grid-sizer,
  .m-grid-item {
    width: 100%;

    @include media-site-breakpoint-up() {
      width: calc(50% - #{$margin-lg});
    }

    @include media-breakpoint-up(xxxl) {
      width: calc(33.333% - #{$margin-lg});
    }
  }

  .m-grid-item {
    @extend .transition-fast;
    float: left;
    margin-bottom: $margin-md;
    opacity: 0;
  }

  &.masonry-loaded {
    .m-grid-item {
      opacity: 1;
    }
  }
}

.page-columns-grid {
  .page-column {
    @extend .padding-std;
    
    .page-header {
      margin-bottom: $margin-md;
      
      @include media-site-breakpoint-up() {
        margin-bottom: $margin-lg;
      }

      @include media-breakpoint-down(xl) {
        h1 {
          font-size: 3rem;
          line-height: 62px;
        }
      }

      @include media-site-breakpoint() {
        h1 {
          font-size: 2rem;
          line-height: 42px;
        }
      }
    }

    &__nrw-right {
      @include media-site-breakpoint-up() {
        padding-right: $space-md;
      }
    }

    &__nrw-left {
      @include media-site-breakpoint-up() {
        padding-left: $space-md;
      }
    }
  }
}

.stacked-rows {
  @extend .list-unstyled;
  
  &__columns-split {
    display: flex;
    border-bottom: $border-std;

    @include media-site-breakpoint() {
      flex-direction: column;
      padding: $margin-25 0;
    }

    &:first-child {
      border-top: $border-std;
    }

    &.even {
      .stacked-rows__column {
        &:last-child {
          @include media-site-breakpoint-up() {
            text-align: right;
          }
        }
      }
    }
    
    .stacked-rows__title {
      margin-bottom: $margin;

      @include media-site-breakpoint() {
        margin-top: $margin;
      }
    }

    .stacked-rows__column {
      flex: 0 1 50%;
      padding: $margin-xl;
      
      @include media-breakpoint-down(xxl) {
        padding: $margin-lg $margin-md;
      }

      @include media-breakpoint-down(xl) {
        padding: $margin-md $margin;
      }

      @include media-site-breakpoint() {
        
        flex-basis: 100%;
        order: 2;

        &.w-image {
          order: 1;
        }

        padding: 0;
      }
    }
    
    .background {
      display: block;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: top;
      background-repeat: no-repeat;
    }

    .stacked-rows__img {
      max-width: 100%;
      width: 100%;
    }

    &.icon {
      .stacked-rows__column {

        @include media-site-breakpoint() {
          text-align: center;
        }
      }

      .stacked-rows__img {
        
        max-width: 300px;

        // @include media-breakpoint-up(xxl) {
        //   max-width: 80%;
        // }

        // @include media-breakpoint-up(xxxl) {
        //   max-width: 60%;
        // }

      }
    }
  }

}