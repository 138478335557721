#site-header {
	height: 100vh;
	position: fixed;
	z-index: $zindex-fixed;
	overflow: hidden;
	
	@extend .transition-fast;

	&.default {
		width: 100%;
		
		@include media-site-breakpoint-up() {
			width: $menu-open-width-md;
		}

		@include media-breakpoint-up(xl) {
			width: $menu-open-width-xl;
		}
	}	

	&.closed {
		@include media-site-breakpoint-up() {
			left: -#{$menu-closed-left-position-md};
		}

		@include media-breakpoint-up(xl) {
			left: -#{$menu-closed-left-position-xl};
		}


		@include media-site-breakpoint() {
			height: $header-height-sm;	
		}
	}

	&.open {
		@include media-breakpoint-up(md) {
			left: 0;
		}

		@include media-site-breakpoint() {
			height: 100vh;	
		}
	}

	&.static {
		flex: 1 1 100%;
		// width: 100%;
		position: relative;

		@include media-site-breakpoint-up() {
			flex: 1 1 50%;
			width: 50%;
			height: 100%;
		}

		@include media-breakheight-sm() {
			height: auto;
		}
	}
	
	.scroll-nav-down {
		display: none;

		@include media-site-breakpoint() {

			.home & {
				display: inline-block;
				left: $margin-25;
				bottom: $margin-25;
			}
		}
	}
	// &:not(.static) {
	// 	position: absolute;
	// 	left: 0;
	// 	top: 0;
	// 	height: 100%;
	// }
}