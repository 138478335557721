.accordion {
	.accordion {

		&-item {
			border-top: $border-std;
			border-bottom: $border-std;
			padding-bottom: $space-md;
		}

		&-header {
			padding-top: $space-md;
			position: relative;
		}

		&-body {
			padding-top: $margin;
			padding-left: $space-lg;
		}

		&-btn {
			appearance: none;
			border: 0;
			outline: none;
			background-color: transparent;
			width: 100%;
			padding: 0;
			text-align: left;
			padding-left: $space-lg;
			cursor: pointer;
			
			svg {
				position: absolute;
				left: 0;
				top: $space-md - 3;
				@extend .transition-fast;

				g, polygon {
					@extend .transition-fast;
				}
			}
			
			&:hover {
				svg {
					.background {
						fill: $primary;
					}

					.lines {
						fill: $white;
					}
				}
			}

			&[aria-expanded="true"] {
				svg {
					transform: rotate(45deg);
				}
			}
		}
	}
}