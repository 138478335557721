.credentials {
	@extend .type-tiny;

	span {
		margin-right: $space-sm;
	}
}

.site-footer {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	&-sub {
		position: relative;
		background-color: $black;
		padding: $margin-25;
		margin-top: $margin;
		color: $gray-light;

		.credentials {
			a {
				color: inherit;
			}
		}

		.footer-copyright {
			display: block;
			margin-bottom: $margin;
		}

		.scroll-nav-up {
			top: $margin-25;
			right: $margin-25;
		}

	}

	&.fixed-footer {
		position: fixed;
		bottom: 0;
		width: 100%;
	}
}
