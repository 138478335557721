a {
	@extend .transition-fast;
	
	&:hover {
		color: $primary;
		text-decoration: none;
	}

	&.link-plain {
		color: $black;
		text-decoration: underline;

		&:hover {
			color: $primary;
		}
	}
}

section {
	@extend .padding-std;

	& + section {
		padding-top: 0;
	}

	&.snug-top {
		padding-top: 0;
	}

	&.section-module {
		border-top: $border-std;
		padding-top: $padding-std;
	}
}

img {
	max-width: 100%;
}