html.magnifying > body {
  overflow-x: hidden !important;
}
.magnify,
.magnify > .magnify-lens,
.magnify-mobile,
.lens-mobile { /* Reset */
  min-width: 0;
  min-height: 0;
  animation: none;
  border: none;
  float: none;
  margin: 0;
  opacity: 1;
  outline: none;
  overflow: visible;
  padding: 0;
  text-indent: 0;
  transform: none;
  transition: none;
}
.magnify {
  position: relative;
  width: auto;
  height: auto;
  box-shadow: none;
  display: inline-block;
  z-index: inherit;
}
.magnify > .magnify-lens { /* Magnifying lens */
  position: absolute;
  width: 500px;
  height: 300px;
  border: 2px solid $black;
  // border-radius: 100%;
  /* Multiple box shadows to achieve the glass lens effect */
  // box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.85),
  //   0 0 7px 7px rgba(0, 0, 0, 0.25),
  //   inset 0 0 40px 2px rgba(0, 0, 0, 0.25);
  cursor: none;
  display: none;
  z-index: 99;
}
.magnify > .magnify-lens.loading {
  background: #333 !important;
  opacity: 0.8;
}
.magnify > .magnify-lens.loading:after { /* Loading text */
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  color: #fff;
  content: 'Loading...';
  font: italic normal 16px/1 Calibri, sans-serif;
  letter-spacing: 1px;
  margin-top: -8px;
  text-align: center;
  text-shadow: 0 0 2px rgba(51, 51, 51, 0.8);
  text-transform: none;
}