.filter-wrap {
	@extend .border-horz;
	padding: $space 0;

	& + .filter-wrap {
		border-top: 0;
	}

	@include media-site-breakpoint() {
		padding: $margin-sm 0;
	}
}

.filters {
	@extend .reset-margin;
	
	&-radio {
		@extend .list-unstyled;
		display: flex;
		justify-content: space-between;
	}

	&-desktop {		
		@include media-breakpoint-tablet() {
			display: none !important;
		}
	}

	&-mobile {
		display: block !important;

		@include media-breakpoint-tablet-plus() {
			display: none !important;
		}
	}

	.filter {

		&-option {
			@extend .reset-margin;

			.text-wrap {
				@extend .h4;
				cursor: pointer;

				&:hover {
					color: $primary;
				}
			}
		}
	}
}