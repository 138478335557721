$font-face-url: '../../assets/fonts/';

@font-face{ 
	font-family: 'texgyreadventor-bold-webfont';
	src: url($font-face-url + 'texgyreadventor-bold-webfont.eot');
	src: url($font-face-url + 'texgyreadventor-bold-webfont.eot?iefix') format('eot'),
	     url($font-face-url + 'texgyreadventor-bold-webfont.woff') format('woff'),
	     url($font-face-url + 'texgyreadventor-bold-webfont.ttf') format('truetype'),
	     url($font-face-url + 'texgyreadventor-bold-webfont.svg#webfont') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face{ 
	font-family: 'texgyreadventor-regular-webfont';
	src: url($font-face-url + 'texgyreadventor-regular-webfont.eot');
	src: url($font-face-url + 'texgyreadventor-regular-webfont.eot?iefix') format('eot'),
	     url($font-face-url + 'texgyreadventor-regular-webfont.woff') format('woff'),
	     url($font-face-url + 'texgyreadventor-regular-webfont.ttf') format('truetype'),
	     url($font-face-url + 'texgyreadventor-regular-webfont.svg#webfont') format('svg');
	font-weight: normal;
	font-style: normal;
}


body {
	font-family: $font-family-base;
	letter-spacing: 0.5px;
	line-height: 26px;
}

.l1,
.l2,
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h6, .h6 {
	font-family: $font-family-heading-bold;
}

h5, .h5,
.type-quote {
	font-family: $font-family-heading;
}

h1, .h1 {
	font-size: $font-size-h1;
	line-height: 46px;

	@include media-site-breakpoint() {
		font-size: $font-size-h1-sm;
		line-height: 42px;
	}
}

h2, .h2 {
	font-size: $font-size-h2;
	line-height: 40px;

	@include media-site-breakpoint() {
		font-size: $font-size-h2-sm;
		line-height: 34px;
	}
}

h3, .h3 {
	font-size: $font-size-h3;
	line-height: 37px;

	@include media-site-breakpoint() {
		font-size: $font-size-h3-sm;
		line-height: 24px;
	}
}

h4, .h4 {
	font-size: $font-size-h4;
	line-height: 27px;

	@include media-site-breakpoint() {
		font-size: $font-size-h4-sm;
		line-height: 22px;
		letter-spacing: 0.5px;
	}
}

h5, .h5 {
	font-size: $font-size-h5;
	line-height: 22px;
	letter-spacing: 0.5px;
}

h6, .h6 {
	font-size: $font-size-h6;
	line-height: 17px;
	letter-spacing: 1.72px;
}

.uc {
	text-transform: uppercase;
}

.text-highlight {
	color: $primary;
}

.text-gray {
	color: $gray-dark;
}

.type-index {
	font-size: $font-size-index;
	line-height: 77.76px;
	letter-spacing: -1.9px;

	@include media-site-breakpoint() {
		font-size: $font-size-h1-sm;
		line-height: 42px;
		letter-spacing: normal;
	}
}

.type-quote {
	font-size: $font-size-h3;
	line-height: 32px;

}

.type-alt-large {
	font-size: 30px;
	font-family: $font-family-heading;
	line-height: 44px;
}

.type-tiny {
	font-size: $font-size-xs;
	letter-spacing: 0.34px;
	line-height: $font-size-base;
}

.type-meta {
	font-size: $font-size-sm;
	font-family: $font-family-heading-bold;
	@extend .text-gray;
}

.type-featured {
	@extend .uc;
	@extend .text-highlight;
}

.type-fineprint {
	font-size: $font-size-fine;
	line-height: 26px;
}

.l1 {
	font-size: $font-size-main-nav;
	line-height: 77.76px;

	@include media-site-breakpoint() {
		font-size: $font-size-main-nav-sm;
		line-height: 40px;
	}
}

.l2 {
	font-size: $font-size-nav;
	line-height: 30px;

	@include media-site-breakpoint() {
		font-size: $font-size-nav-sm;
		letter-spacing: 0.5px;
		line-height: $font-size-base;
	}
}

h1.page-title {
	@extend .type-index;
}
