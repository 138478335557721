.post-content {
	margin-bottom: $space-lg;
	
	h1, h2, h3 {
		@include media-site-breakpoint() {
			margin-bottom: $space-md;
		}
	}

	&.space-md {
		margin-bottom: $space-md;
	}

	&.snug {
		margin-bottom: 0;
	}

	strong {
		@extend .l2;
	}

	blockquote {
		@extend .quote-box;

		p {
			@extend .quote-box-content;
		}
	}

	&__section-sub-title,
	h4 {
		color: $gray-dark;
	}

	&__list-simple {
		@extend .list-unstyled;
		
		li {
			margin-bottom: $space-sm;
		}

		a {
			color: $black;
			text-decoration: underline;
			@extend .ellipsis;
			
			&:hover {
				color: $primary;
			}
		}
	}

	&__list-bars {
		@extend .list-unstyled;

		li {
			@extend .h5;
			margin-bottom: 0;
			position: relative;
			display: flex;
			align-items: center;

			&:before {
				content: '';
				width: $space;
				height: 1px;
				background-color: $black;
				display: inline-block;
				margin-right: 5px;
			}

		}
	}

	&__list-links {
		@extend .list-unstyled;

		li {
			// @extend .type-tiny;
			display: flex;
			align-items: center;
			margin-bottom: $space;

			a {
				color: $black;
				text-decoration: underline;

				&:hover {
					color: $primary;
				}
			}
		}
	}

	&__details {
		@extend .type-tiny;
	}

}