.media-item {
	&__featured {
		position: relative;
		background-color: $black;
		overflow: hidden;
		cursor: pointer;
		height: 100%;
		
		.media-item__img {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center left;
		}

		.media-item__img,
		iframe {
			position: absolute;
		}

		.media-item__img,
		iframe {
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
		}
	}

	&__info-icon {
		display: flex;
	}
	
	&__info-content {
		margin-left: $margin;
	}

	&__info {
		position: absolute;
	    bottom: 0;
	    left: 0;
	    width: 100%;
	    padding: $margin $margin-md;
	    display: flex;
	    align-items: center;
	    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.22) 24%,rgba(0,0,0,0.65) 100%);
		
		.media-item__title {
			color: $white;
			margin-bottom: 0;	
		}
	    .media-item__meta {
	    	@extend .h5;
	    	color: $gray-light;
	    	margin-bottom: 0;
	    }
	}
}

.result-item {
	
	&__term {
		@extend .text-highlight;
		@extend .uc;
	}

	&__thumbnail {
		img {
			width: 100%;
		}
	}

	&__meta {
		@extend .h5;
	}

	&__hover {
		@extend .media-pos-item;
		@extend .transition-fast;
		opacity: 0;
		
	}

	&__action {
		@extend .h4;
		@extend .reset-margin;
	}	
	
	&__inline-title {
		display: flex;
		
		.result-item__title {
			@extend .ellipsis;
			line-height: 1.4;
			
			@include media-site-breakpoint() {
				text-overflow: initial;
				white-space: normal;
			}
			
		}

		.result-item__action {
			display: flex;
			align-items: center;
			margin-left: $margin;
			white-space: nowrap;

			svg {
				margin-left: $space-sm;
			}
		}
	}

	a {
		&:hover {
			.result-item__hover {
				opacity: 1;
			}
		}
	}
	
	&.team_member-item {
		margin-bottom: $space-md;
		
		.result-item {
			&__title {
				margin-top: $margin-sm;
				margin-bottom: 0;
			}
		}

		a {
			color: $black;

			&:hover {
				color: $primary;

				.result-item__meta {
					color: $black;
				}
			}
		}
	}

	&.dictionary-item {

		.result-item {

			&__title {
				@extend .type-quote;
				@extend .reset-margin;
				flex: 0 1 67%;
				
				@include media-breakpoint-down(xl) {
					flex-basis: 55%;
				}
			}

			&__action {
				flex: 0 1 33%;
				text-align: right;
				padding-top: 2px;

				@include media-breakpoint-down(xl) {
					flex-basis: 45%;
				}
			}
		}


		a {
			display: flex;
			justify-content: space-between;
			// align-items: center;
			padding: $margin 0;
			border-bottom: $border-std;

			color: $black;

			&:hover {
				color: $primary;
			}
		}

		&:first-child {
			a {
				border-top: $border-std;
			}
		}
	}

	&.post-item {
		.result-item {

			&__inner {
				@include media-site-breakpoint() {
					display: flex;
					border-bottom: $border-std;
				}
			}

			&__title {
				color: $black;

				@include media-site-breakpoint() {
					font-size: $font-size-base;
				}
			}

			&__meta {
				font-family: $font-family-heading-bold;
				color: $gray-dark;

				@include media-site-breakpoint() {
					margin-bottom: $space-md;
				}
			}
			
			&__content {
				@include media-site-breakpoint() {
					order: 2;
					flex: 0 1 60%;
				}
			}

			&__thumbnail {
				position: relative;
				padding-bottom: $space;
				border-bottom: $border-std;

				@include media-site-breakpoint() {
					order: 1;
					flex: 0 1 40%;
					padding-right: $margin;
					padding-bottom: $space-md;
					border-bottom: none;
				}
			}

			&__hover {
				height: calc(100% - #{$space});
				background-color: $bg-hover-white;
				border: $border-plus;
				padding: $space-md;
			}
			
			&__hover-icon {
				width: auto;
				max-width: 50%;
				display: block;
				margin-bottom: $margin;
			}

			&__hover-text {
				@extend .h4;
				svg {
					margin-left: $space-sm;
				}
			}
		}
	}

	&.video-item,
	&.radio-item {
		margin-bottom: $space-md;

		.result-item {
			&__thumb-wrap {
				@extend .media-pos-wrap;
			}

			&__thumbnail {
				@extend .media-pos-item;
				@extend .media-pos-bg;
			}

			&__hover {
				background-color: $bg-hover-gray;
			}

			&__hover-text {
				@extend .h4;
				@extend .reset-margin;
				position: absolute;
				bottom: $space;
				left: $space;
				color: $white;

				.icon-arrow {
					margin-left: $margin-sm;
				}
			}

			&__content {
				display: flex;
				align-items: flex-start;
				margin-top: $space;

				.icon-play,
				svg {
					// width: $space-md;
					height: auto;
					flex: 1 1 30px;

					@include media-site-breakpoint() {
						width: $space-md + 5;
					}
				}
			}

			&__info {
				margin-left: $space;
				flex: 1 1 85%;
			}

			&__title {
				@extend .reset-margin;
				@extend .transition-fast;
			}

			// &__meta {
			// 	@extend .h5;
			// }

		}

		a {
			color: $black;
			// @extend .transition-fast;

			&:hover {
				.result-item__title {
					color: $primary;
				}
			}
		}
	}

	&.search-item {
		border-bottom: $border-std;
		padding-top: 25px;
		padding-bottom: 25px;
		
		&:last-child {
			border-bottom: none;
		}
		
		a {
			color: $black; 

			&:hover {
				color: $primary;
			}
		}
		
		.result-item__inline-title {
			@include media-site-breakpoint() {
				flex-direction: column;
			}
		}

		.result-item__action {
			@include media-site-breakpoint() {
				margin-left: 0;
			}
		}

		.result-item__term {
			@include media-site-breakpoint() {
				font-size: $font-size-xs;
				line-height: 17px;
			}
		}
	}
}