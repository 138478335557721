.related-content {

	&__module-title {
		@extend .text-gray;
		margin-bottom: $margin-md;
	}

	&__list {
		@extend .list-unstyled;
	}

	&__item {

		@include media-site-breakpoint() {
			margin-bottom: $space-md;
		}

		a {
			@extend .transition-fast;
			color: $black;

			&:hover {
				.related-content__title {
					color: $primary;
				}
			}
		}
	}

	&__title {
		@extend .reset-margin;
		@extend .transition-fast;
		margin-top: $space;
	}

	&__thumbnail {
		position: relative;
		padding-bottom: 56%;
		overflow: hidden;
		background-size: cover;
		background-position: center top;
		background-repeat: no-repeat;

		img {
			position: absolute;
			top: 0;
			left: 0;
		}

		svg {
			position: absolute;
			bottom: $space;
			left: $space;
			width: $margin-md;
			height: auto;

			&.icon-play {
				#Path {
					fill: $black;
				}
			}
		}
	}

	&.team {
		.related-content__thumbnail {
			padding-bottom: 65%;
		}
	}
}