$class-slug: menu-order !default;
$menu-z-index: 30;

.nav-primary {
	a {
		color: $gray-dark;

		&:hover {
			color: $white;
			text-decoration: none;
		}
	}

	ul {
		@extend .list-unstyled;
	}

	.nav {
		> li {
			display: block;
			width: 100%;

			
			> svg {
				margin-left: $margin;
			}

			> a {
				@extend .l1;

				@include media-breakheight-md() {
					font-size: 2.875rem;
					line-height: 55px;
				}
			}

			&.active {
				> a {
					color: $white;
				}
			}

			@include media-breakheight-sm() {
				&.menu-item-open {
					z-index: 100;
				}
			}
		}

		@for $i from 1 through 20 {
		  .#{$class-slug}-#{$i} {
		    z-index: $menu-z-index - $i;
		  }
		}


		.submenu {
			padding-left: $margin-lg;

			@include media-site-breakpoint() {
				padding-left: $space-lg;
			}

			@include media-breakheight-sm() {
				.site-container-default & {
					position: absolute;
					right: 0;
					width: 50%;
					top: 30px;
				}
			}

			.link {
				@extend .l2;

				@include media-breakheight-md() {
					font-size: $font-size-lg;
					line-height: 28px;
				}
			}

			&-inner {
				position: relative;
				margin: $space 0;

				@include media-breakheight-md() {
					margin: $margin-sm 0;
				}
			}

			.menu-stroke-arrow {
				position: absolute;
				left: -$margin-lg;
				top: 0;
				height: 5%;
				transition-property: height;
			    transition-duration:0.8s;
			    // transition-delay:0.1s;
			    transition-timing-function: ease-in;

				@include media-site-breakpoint() {
					left: -$space-lg;
				}
			}

			&.show {
				.menu-stroke-arrow {
					height: 100%;
				}
			}

		}
	}
}