.featured {
	.video-item__featured {
		height: 100%;

		.media-item__meta {
			font-family: $font-family-heading-bold;
		}
	}

	&-media__container {
		
		@include media-site-breakpoint-up() {
			border: $border-std;
		}

		.featured-media__item-wrapper {
			@extend .media-pos-wrap;
			padding-bottom: $height-widescreen;
		}

		.featured-media__thumb {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}

		.featured-media__hover {
			@extend .transition-fast;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				@extend .icon-play-lg;

				#microphone---FontAwesome {
					fill: $primary;
				}
			}
		}

		.featured-media__thumb,
		.featured-media__hover,
		iframe {
			@extend .media-pos-item;
		}

		.featured-media__content {
			@extend .padding-tall-box-no-btm;

			@include media-breakpoint-down(xl) {
				padding-left: $space-md;
				padding-right: $space-md;
			}
		}

		.featured-media__title {
			margin-bottom: $space;
			color: $black;
			@extend .transition-fast;

			@include media-breakpoint-down(xxl) {
				font-size: 1.5rem;
			}
		}

		.featured-media__meta {
			@extend .h5;
			color: $black;
		}

		.featured-media__author {
			@extend .type-meta;
		}

		&:hover {
			.featured-media__title {
				color: $primary;
			}

			.featured-media__hover {
				background-color: $bg-hover;
			}
		}
	}
}