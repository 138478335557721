.zoombox-wrapper{
  width: 100%;
  padding-bottom: 75%; /* 16:9 */
  position: relative;
  background: transparent;
  overflow: hidden;
      
  img{
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    color: white;
    font-size: 24px;
    text-align: center;
  }
}

#glass {
    position: absolute;
    border-radius: 0;
    display: none;
    background-repeat: no-repeat;
    background-color: rgba(0,0,0,.6);
    border: 2px solid black;
}
