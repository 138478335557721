.video-component {
	&__wrap {
		position: relative;
		width: 100%;
		padding-bottom: $height-widescreen;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

.content-component {

	&__term {
		@extend .type-meta;

		@include media-site-breakpoint() {
			margin-bottom: $space-sm;
		}
	}
	
	&__title {
		margin-bottom: $margin-lg;

		@include media-site-breakpoint() {
			font-size: $font-size-h2-sm;
			margin-bottom: $space-md;
		}
	}

	&__meta {
		@extend .h5;
		margin-top: 5px;
		margin-bottom: $margin-md;

		@include media-site-breakpoint() {
			margin-bottom: $space-md;
		}
	}

	.post-content {
		margin-bottom: 0;
	}
}