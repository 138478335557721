// input-set animation styling
// --------------------
$input-set-padding: 12px;

.input-set {
	position: relative;
	width: 100%;
	font-family: $font-family-heading;
	font-size: $font-size-h5;
	
	&__field {
		padding: $input-set-padding $input-set-padding 0;
		font-size: $font-size-h5;
	}

	textarea {
		max-width: 100%;
		height: 200px;
	}

	&__label {
		position: absolute;
		margin-bottom: 0;
	    top: $input-set-padding;
	    left: $input-set-padding + 1;
	    z-index: 1;
	    @extend .transition-fast;
	}

	&__focus, &__filled, &.autofocus {
		.input-set__label {
			font-family: $font-family-heading-bold;
		    font-size: $font-size-xs;
		    top: 2px;
		}

		textarea {
			padding-top: $margin;
		}
	}

	.form-centered & {

		&__label {
			width: 100%;
			left: 0;
		}

		&__field {
			text-align: center;
		}
	}
}