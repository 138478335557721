.ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.transition-fast {
	transition: all $ani-fast;
}

.menu-cls-hide {

	opacity: 1;
	@extend .transition-fast;


	.closed & {
		opacity: 0;
		
		@include media-site-breakpoint() {
			visibility: hidden;
		}
	}
	@include media-site-breakpoint-up() {
	}
}

.menu-cls-show {
	opacity: 0;
	@extend .transition-fast;

	.closed & {
		opacity: 1;

	}
}

.box-padding {
	padding: $margin-lg;

	@include media-site-breakpoint() {
		padding: $margin-25;
	}
}

.padding-sm {
	padding-top: $padding-sm;
	padding-bottom: $padding-sm;
}

.padding-md {
	padding-top: $padding-md;
	padding-bottom: $padding-md;
}

.padding-std {
	padding-top: $padding-std;
	padding-bottom: $padding-std;

	@include media-site-breakpoint() {
		padding-top: $margin-25;
		padding-bottom: $margin-25;
	}
}

.padding-std-box {
	padding: $padding-std;
}

.padding-tall-box-no-btm {
	padding: $space-md $margin-lg 0;

	@include media-site-breakpoint() {
		padding: $space-md $margin-25 0;
	}
}

.border-std {
	border: $border-std;
}

.border-horz {
	border-top: $border-std;
	border-bottom: $border-std;
}

.border-btm {
	border-bottom: $border-std;
}

.reset-margin {
	margin: 0;
}

.bg-hover {
	background-color: $bg-hover;
}

.media-pos-wrap {
	position: relative;
	padding-bottom: 45%;
	padding-top: $margin;
	height: 0;
	background-color: $black;
}

.media-pos-bg {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
}

.media-pos-item {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

.margin {
	&-btm {
		&-lg {
			margin-bottom: $margin-lg;
		}
	}

	&-top {
		&-md {
			margin-top: $space-md;
		}
	}
}

.quote-box {
	position: relative;
	border-left: 4px solid $primary;
	padding-left: $margin-lg;
}

.quote-box-content {
	font-size: $font-size-h3;
	font-family: $font-family-heading;
	line-height: 37px;
	@extend .text-gray;
}

.hidden-main-under-breakpoint {
	.site-container-fixed & {
		@include media-site-breakpoint() {
			display: none !important;
		}
	}
}

.hidden-under-grid-breakpoint {
	@include media-site-breakpoint() {
		display: none !important;
	}
}

.hidden-over-grid-breakpoint {	
	@include media-site-breakpoint-up() {
		display: none !important;
	}
}