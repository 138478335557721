button, 
.btn,
.btn-link {
	outline: none;
	@extend .transition-fast;

	&:hover,
	&:focus,
	&:active {
		outline: none;
		text-decoration: none;
		box-shadow: none;
	}
}

.btn {
	min-width: 200px;
	display: flex;
	align-items: center;
	justify-content: center;

	&-primary {
		&:hover {
			border-color: $black;
			background-color: $black;
		}

		&:focus {
			outline: none;
			box-shadow: none;
		}
	}

	&-md {
		height: $btn-height-md;
		max-width: 250px;
		width: 100%;
	}

	&-block {
		max-width: 100%;
		width: 100%;
	}

	&-xs-block {
		@include media-site-breakpoint() {
			max-width: 100%;
			width: 100%;
		}
	}
}

button {
	box-shadow: none;
	outline: none;
	appearance: none;

	&.slider-dot,
	&.nav-prev,
	&.nav-next {
		// box-shadow: none;
		// outline: none;
		// appearance: none;
		cursor: pointer;
		border: none;
	}

	&.nav {
		&-prev,
		&-next {
			background-color: transparent;
			position: absolute;
		}

		&-prev {
			left: 0;
			&:before {
				content: url('../icons/ui_icon_prev.svg');
				display: inline-block;
			}
		}

		&-next {
			right: 0;
			&:before {
				content: url('../icons/ui_icon_next.svg');
				display: inline-block;
			}
		}
	}


	&.slider-dot {
		height: $margin-sm;
		width: $margin-sm;
		border-radius: 50%;
		background-color: $gray-light;
		padding: 0;
		margin: 0 $space-xxs;
		
		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}

		&.active {
			background-color: $gray-dark;
		}
	}
}


.btn {
	&-md {
		@extend .h4;
	}

	&-link {
		border: none;
		padding: 0;
		margin: 0;
		cursor: pointer;

		color: $gray-dark;
		
		.link-icon {
			color: $primary;
		}

		&.dark {
			color: $black;
			
		}
		
		&.light {
			color: $white;
		}

		&:hover {
			color: $primary;
			text-decoration: none;
		}


	}
}

.scroll-nav {
	position: absolute;
	// display: inline-block;
	width: auto;
	cursor: pointer;
	
	&-text {
		@extend .type-tiny;
		@extend .uc;
		color: $gray-dark;
		display: block;
	}

	svg {
		height: auto;
	}

	&-up,
	&-down {
		svg {
			width: $margin;
		}
	}

	&-page {
		position: fixed;
		bottom: $margin-lg;
		left: $margin-25;
		text-align: center;
		@extend .transition-fast;
		opacity: 0;
		
		&[data-scroll="page"] {
			.icon-scroll-up {
				display: none;
			}
		}

		&[data-scroll="top"] {
			.icon-scroll-down {
				display: none;
			}
		}

		@include media-site-breakpoint() {
			display: none;
		}

		@include media-site-breakpoint-up() {
			left: $menu-closed-width-sm + $space-md;
		}

		@include media-breakpoint-up(xl) {
			left: $menu-closed-width-xl + $space-md;
		}
	}
}