.footer-nav {
	margin-bottom: $margin;

	li {
		margin-right: $space-md;

		a {
			@extend .h5;

			&:hover {
				color: $white;
			}
		}
	}
}