.site-container {
	background-color: $black;

	#site-content {
		position: relative;
		background-color: $white;
	}

	&.site-container-default {
		#site-content {
			@include media-site-breakpoint-up() {
				padding-left: $menu-closed-width-sm;
			}

			@include media-breakpoint-up(xl) {
				padding-left: $menu-closed-width-xl;
			}

			@include media-site-breakpoint() {
				padding-top: $header-height-sm;
			}
		}
	}

	&.site-container-fixed {
		display: flex;
		flex-direction: column;

		@include media-site-breakpoint-up() {
			flex-direction: row;
			height: 100vh;
		}

		@include media-breakheight-sm() {
			min-height: $body-min-height-sm;
			height: 100%;
		}

		#site-content {
			flex: 1 1 100%;

			@include media-site-breakpoint-up() {
				flex: 1 1 50%;
				max-width: 50%;
			}
		}

		.stacked-grid {
			.grid-row {
				height: 33.333vh;
				
				@include media-breakheight-sm() {
				    height: 33%;
				}

				&.grid-expand {
					@include media-site-breakpoint() {
						height: auto;
					}
				}
			}
		}

	}

}

.page {
	&-header {
		.article-meta {
			margin-top: $space-md;
		}
	}

	&-search {
	}
	
	&-hero-section {
		min-height: $hero-section-height-lg;
		position: relative;

		@include media-site-breakpoint() {
			padding-top: calc(#{$hero-section-height-sm} + #{$space-md});
		}
		// &__color-white {
		// }

		// &__color-black {
		// 	background-color: $white;
		// }

		.page-title {
			@include media-site-breakpoint() {
				margin-bottom: $margin-25;
			}
		}

		&__bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;

			@include media-site-breakpoint() {
				height: $hero-section-height-sm;
			}

		}

		&__content-wrap {

			@include media-site-breakpoint-up() {
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				width: 100%;
				display: flex;
				align-items: center;
			}
		}

		&__content {

			> * {
				margin-bottom: $margin;

				@include media-site-breakpoint() {
					margin-bottom: $margin-25;
				}
			}

			a {
				@extend .h4;
				margin-bottom: $space;
				display: block;

				svg {
					margin-left: $space-xs;
				}
			}

		}
	}

	&-section__color {
		&-white {
			background-color: $black;

			color: $white;
			a {
				color: $white;

				&:hover {
					color: $primary;
				}
			}
		}

		&-black {
			color: $black;

			a {
				color: $black;

				&:hover {
					color: $primary;
				}
			}
		}
	}
}